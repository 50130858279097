import {
  createClient as urqlCreateClient,
  errorExchange,
  fetchExchange,
  Provider as UrqlProvider,
} from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import { offlineExchange } from '@urql/exchange-graphcache';
import { authExchange } from '@urql/exchange-auth';
import appsignal, { NAMESPACES } from 'app-lib/appsignal';
import { config, config as appConfig } from 'app-config';
import {
  computeLocalBundle,
  getAccessToken,
  getRefreshToken,
  isAccessTokenExpired,
  updateBundle,
} from 'app-services/models/rest/bundle';
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isBrowser } from 'app-utils/isEnv';
import router from 'next/router';
import { refocusExchange } from '@urql/exchange-refocus';
import { requestPolicyExchange } from '@urql/exchange-request-policy';
import { tokenExpireTime } from 'app-services/api/identityApi';
import { LeadCurrentMandatoryTasks } from 'app-graphql';
import { anonymiseData } from 'app-utils/appsignal/sanitizeErrorMessage';
// `no-unresolved` is disabled here because schema.json is only generated at build time.
// eslint-disable-next-line import/no-unresolved
import schema from '../../../tmp/schema.json';

// Creates a persistent user specific cache storage.
const initStorage = () =>
  isBrowser
    ? makeDefaultStorage({
        idbName: 'urql-cache',
        maxAge: 1,
      })
    : null;

/**
 * Create a `urql` client
 *
 * @param {import('urql').ClientOptions} opts
 * @return {Client}
 */
export const createClient = (opts) =>
  urqlCreateClient({
    requestPolicy: 'cache-and-network',
    url: `${config.endpoints.gateway}/graphql`,
    exchanges: [
      devtoolsExchange,
      requestPolicyExchange({
        // 2 minutes
        ttl: 2 * 60 * 1000,
      }),
      refocusExchange(),
      offlineExchange({
        schema,
        keys: {
          // Silence warnings for objects without IDs; they can't be cached.
          PropertyEquipment: () => null,
          PropertyValuation: () => null,
          PropertyObjectDetails: () => null,
          LeadContact: () => null,
          LeadObjectProperties: () => null,
          LeadStateProperties: () => null,
          AssetPage: () => null,
          BrokerContact: () => null,
          BrokerPage: () => null,
          LeadPage: () => null,
          TaskPage: () => null,
          PaymentMandatePage: () => null,
          InvoiceAddressPage: () => null,
          OfficePage: () => null,
          PaymentItem: () => null,
          PaymentPage: () => null,
          AccountBalancePackagePage: () => null,
        },
        resolvers: {
          Queries: {
            // Return partial (already-retrieved) lead data for detail pages
            lead: (_, args, cache) =>
              cache.keyOfEntity({
                __typename: 'Lead',
                id: args.id,
              }),
          },
        },
        updates: {
          Mutations: {
            task(result, args, cache) {
              // Handles new task creation only as updates are handled automatically.
              if (result?.task?.create?.task) {
                // Invalidates the lead which is referenced by the new task.
                cache.invalidate({
                  __typename: 'Lead',
                  // Extract the id from given gid.
                  id: result.task.create.task.owner?.gid?.split('/').pop(),
                });
              }
            },
            lead(result, args, cache) {
              /*
               * Updates the 'currentMandatoryTaskName' within lead manually
               * as backend does not return the new task in response.
               * This is a "flaw" by there design as the request triggers a bunch of several
               * updates on their distributed database. The request however response to us
               * (more or less) right after the first acknowledge (data and permission is checked)
               * not after all related actions have finished. This way some/most data already have updated
               * but never the 'currentMandatoryTaskName' as this data field is more complex.
               *
               * Note: Backend is aware of that and will think of a solution for that somewhen
               *   as there is no easy solution for and will require effort on back- and frontend.
               */
              // FIXME: Add other "phases"/accordions as well
              if (result?.lead?.updateMandatoryTask?.lead) {
                cache.updateQuery(
                  {
                    query: `query ($id: LeadGlobalIdOrUuid!) {
                    lead(id: $id) {
                    id,
                    currentMandatoryTaskName,
                    stateProperties {
                      acquisitionCompleted,
                      brokerContractDate,
                      brokerContractPrice,
                      brokerContractCommissionUnit,
                      brokerContractCommission,
                      brokerContractBuyerCommission,
                      brokerContractTermDate,
                      brokerContractBuyerCommissionUnit,
                      brokerContractType,
                      expectedSalePrice,
                      expectedSaleNotaryDate,
                      objectSaleBuyerCommission,
                      objectSaleBuyerCommissionTaxation,
                      objectSaleBuyerCommissionUnit,
                      objectSaleCommission,
                      objectSaleCommissionTaxation,
                      objectSaleCommissionUnit,
                      objectSaleNotaryDate,
                      objectSalePrice,
                      marketingCompleted,
                      }
                    }
                  }`,
                    variables: {
                      id: result?.lead?.updateMandatoryTask?.lead?.id,
                    },
                  },
                  (data) => {
                    // Do nothing if no data found
                    if (!data) return null;
                    // Update "Phase valuation" as completed
                    if (
                      data.lead.stateProperties?.acquisitionCompleted &&
                      (data.lead.currentMandatoryTaskName ===
                        LeadCurrentMandatoryTasks.PlanValuationMeeting ||
                        data.lead.currentMandatoryTaskName ===
                          LeadCurrentMandatoryTasks.HaveValuationMeeting)
                    ) {
                      // eslint-disable-next-line no-param-reassign
                      data.lead.currentMandatoryTaskName =
                        LeadCurrentMandatoryTasks.SignMarketingContract;
                    }

                    /*
                     * Update phase marketing and set task SignMarketingContract
                     * as completed
                     */
                    if (
                      data.lead.stateProperties?.brokerContractDate !== null &&
                      data.lead.stateProperties?.brokerContractPrice !== null &&
                      data.lead.stateProperties
                        ?.brokerContractCommissionUnit !== null &&
                      data.lead.stateProperties?.brokerContractCommission !==
                        null &&
                      data.lead.stateProperties
                        ?.brokerContractBuyerCommission !== null &&
                      data.lead.stateProperties
                        ?.brokerContractBuyerCommissionUnit !== null &&
                      data.lead.stateProperties?.brokerContractType !== null &&
                      data.lead.stateProperties?.brokerContractTermDate !==
                        null &&
                      data.lead.stateProperties?.expectedSalePrice !== null &&
                      data.lead.currentMandatoryTaskName ===
                        LeadCurrentMandatoryTasks.SignMarketingContract
                    ) {
                      // eslint-disable-next-line no-param-reassign
                      data.lead.currentMandatoryTaskName =
                        LeadCurrentMandatoryTasks.PlanNotaryDate;
                    }

                    /*
                     * Update task PlanNotaryDate and phase marketing as completed
                     */
                    if (
                      data.lead.stateProperties?.expectedSaleNotaryDate !==
                        null &&
                      data.lead.stateProperties?.marketingCompleted &&
                      LeadCurrentMandatoryTasks.PlanNotaryDate
                    ) {
                      // eslint-disable-next-line no-param-reassign
                      data.lead.currentMandatoryTaskName =
                        LeadCurrentMandatoryTasks.SignContractAtNotary;
                    }

                    /*
                     * Update task SignNotaryContract as completed
                     */
                    if (
                      data.lead.stateProperties?.objectSaleBuyerCommission !==
                        null &&
                      data.lead.stateProperties
                        ?.objectSaleBuyerCommissionTaxation !== null &&
                      data.lead.stateProperties
                        ?.objectSaleBuyerCommissionUnit !== null &&
                      data.lead.stateProperties?.objectSaleCommission !==
                        null &&
                      data.lead.stateProperties
                        ?.objectSaleCommissionTaxation !== null &&
                      data.lead.stateProperties?.objectSaleCommissionUnit !==
                        null &&
                      data.lead.stateProperties?.objectSaleNotaryDate !==
                        null &&
                      data.lead.stateProperties?.objectSalePrice !== null &&
                      data.lead.currentMandatoryTaskName ===
                        LeadCurrentMandatoryTasks.SignContractAtNotary
                    ) {
                      // eslint-disable-next-line no-param-reassign
                      data.lead.currentMandatoryTaskName =
                        LeadCurrentMandatoryTasks.SendContractCopyToUs;
                    }

                    return data;
                  }
                );
              }
            },
          },
        },
        storage: initStorage(),
      }),
      errorExchange({
        /*
         * Checks if the request failed and creates an AppSignal error for.
         * This is done here instead of in `onError` because we have access to the original request here.
         */
        onResult(result) {
          /*
           * Get error from mutation response. Besides the "flat" request error at `result.error` there can also be
           * errors from the mutation itself, which are nested deeper depending on the mutation.
           * As we only do one mutation per request ("contract" with backend) and the mutation responses have always the
           * same schema `subject-predicate-"response"` like in `paymentMandate-create-"response"` (see mutation.graphql:17)
           * we can search for those nested errors relatively cheap. `result.data[subjectKey][predicateKey].error`.
           * In case we already have a "flat" error we do not need to search for the nested one.
           */
          const inDeepError =
            !result?.error &&
            result?.data?.[Object.keys(result.data)?.[0]]?.[
              Object.keys(result.data[Object.keys(result.data)[0]])?.[0]
            ]?.error;

          if (result?.error || inDeepError) {
            // Tries to use the name of the urql request as error name, so AppSignal errors are grouped by the request instead of generic classes. Fallback to those if this is not possible.
            const getErrorName = (resultObject) => {
              const queryString =
                resultObject?.operation?.query?.loc?.source?.body;
              if (queryString) {
                const regexMatchQuery = queryString?.match(/query ([^(]*)/);
                if (regexMatchQuery?.[1]) {
                  return `Query: ${regexMatchQuery[1]}`;
                }

                const regexMatchMutation =
                  queryString?.match(/mutation ([^(]*)/);
                if (regexMatchMutation?.[1]) {
                  return `Mutation: ${regexMatchMutation[1]}`;
                }
              }

              return resultObject.error.name;
            };

            const span = appsignal.createSpan();
            // We create our own error object, so the given one from urql is not overwritten. But we keep the message from.
            const newError = new Error(
              result?.error?.message ||
                inDeepError?.message ||
                'Unknown urql error'
            );
            newError.name = getErrorName(result);
            span.setError(newError);
            span.setParams({
              variables: anonymiseData(result?.operation?.variables),
            });
            span.setNamespace(NAMESPACES.GraphQL);
            appsignal.send(span);
          }

          // Move the nested error to the position of the "flat" error if exists (there is always only one of the error types) to make it easier accessible.
          if (inDeepError) {
            return {
              ...result,
              error: inDeepError,
            };
          }

          return result;
        },
      }),
      authExchange((utils) =>
        Promise.resolve({
          async refreshAuth() {
            try {
              const refreshToken = getRefreshToken(computeLocalBundle());

              // Skip refresh request if no refresh token is provided.
              if (!refreshToken) {
                throw new Error('No refresh token.');
              }

              // Request new bundle (with new tokens).
              const response = await fetch(
                `${appConfig.endpoints.identityApi}/v1/jwt/refresh`,
                {
                  method: 'post',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    refresh_token: refreshToken,
                    expires_in: tokenExpireTime,
                  }),
                }
              ).then((_response) => _response.json());
              // Throw error cases.
              if (response.code)
                throw new Error('An error occurred while refreshing.');
              if (!response?.access_token) throw new Error('No access token.');

              updateBundle(response, 'refresh');
            } catch {
              // Log out if refreshing token went wrong.
              await router.push('/logout');
            }
          },
          willAuthError: () => isAccessTokenExpired(computeLocalBundle()),
          didAuthError(error) {
            return error.graphQLErrors.some(
              (e) =>
                /*
                 * This means that an invalid or an expired token will trigger a logout instead of silently crashing the app,
                 * reducing errors sent to AppSignal by failing GQL requests.
                 */
                e.message.includes('JWT is invalid') ||
                e.message.includes('HTTP Token: Access denied')
            );
          },
          addAuthToOperation(operation) {
            const accessToken = getAccessToken(computeLocalBundle());

            if (!accessToken) return operation;
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${accessToken}`,
            });
          },
        })
      ),
      fetchExchange,
    ],

    ...opts,
  });

export const ClientContext = React.createContext({
  resetClient: null,
});

/**
 * ClientProvider
 *
 * Provides `urql` client and allows resetting it via `useClient` to clear caches.
 *
 * @see https://github.com/FormidableLabs/urql/issues/297#issuecomment-504782794
 */
export const ClientProvider = ({
  createClient: createClientProp,
  children,
}) => {
  const [client, setClient] = React.useState(createClientProp());

  const initializeClient = React.useCallback(async () => {
    setClient(createClientProp());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clientContextValue = useMemo(
    () => ({
      resetClient: () => initializeClient(),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <ClientContext.Provider value={clientContextValue}>
      <UrqlProvider value={client}>{children}</UrqlProvider>
    </ClientContext.Provider>
  );
};

ClientProvider.propTypes = {
  createClient: PropTypes.func,
  children: PropTypes.element,
};

ClientProvider.defaultProps = {
  createClient,
  children: null,
};

/**
 * `useClient`
 */
export const useClient = () => React.useContext(ClientContext);
