import { config, isLocal } from 'app-config';
import * as snippet from '@segment/snippet';
import usePWADisplayMode from 'app-utils/hooks/usePWADisplayMode';
import { useCallback, useEffect, useState } from 'react';
import { anonymizeUrl } from 'app-utils/compute/anonymizeUrl';

/**
 * Provides segment render snippet and tracking functions with an eventName and optional properties
 */

const useTracking = () => {
  const { isPWA, currentDisplayMode } = usePWADisplayMode();

  const [displayModeChanged, setDisplayModeChanged] = useState(false);

  /**
   * Provides a renderSegmentSnippet function that loads Analytics.js from Segment onto the page asynchronously.
   *
   */
  const renderSegmentSnippet = () => {
    // On development env there is no key provided, so we do not need to load this script which raise an error about unset api key each time.
    if (!config.segment.apiKey) {
      return function segmentDummy() {};
    }

    const opts = {
      apiKey: config.segment.apiKey,
      /*
       * Note: `page` only covers SSR tracking.
       * Page.jsx is used to track other events using `window.analytics.page()`
       * Deactivated as we now fire segment.page() once in an useEffect in
       *  _app.page.jsx
       */
      page: false,
    };

    if (isLocal) {
      return snippet.max(opts);
    }

    return snippet.min(opts);
  };

  /**
   * Provides a tracking function for segment with an eventName and optional properties
   *
   * @see [FRONTEND-CONVENTION](https://github.com/hausgold/knowledge/blob/master/architecture/decisions/Frontend-Developer-Manual.md)
   *
   * @param eventName - For event names we use the object-action Framework
   * @param properties - Properties are optional and should contain everything that help us to track the event even more detailed.
   */
  const segmentTrack = useCallback(
    (eventName: string, additionalProperties?: Record<string, unknown>) => {
      if (!eventName || typeof eventName !== 'string') {
        return;
      }

      if (
        typeof additionalProperties === 'object' &&
        Object.keys(additionalProperties).length
      ) {
        // Track the event with the additional properties
        window?.analytics?.track(eventName, {
          usesPWA: isPWA?.toString(),
          appDisplayMode: currentDisplayMode,
          path: anonymizeUrl(window.location.pathname),
          ...additionalProperties,
        });
      } else {
        // Track the event only, _without_ additional properties
        window?.analytics?.track(eventName, {
          usesPWA: isPWA?.toString(),
          appDisplayMode: currentDisplayMode,
          path: anonymizeUrl(window.location.pathname),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displayModeChanged]
  );

  /**
   * Provides a page function for segment which lets you record page views, along with optional information.
   */
  const segmentPage = useCallback(
    (additionalProperties?: Object) => {
      if (!additionalProperties) {
        return;
      }

      if (typeof additionalProperties === 'object') {
        window?.analytics?.page({
          usesPWA: isPWA?.toString(),
          appDisplayMode: currentDisplayMode,
          ...additionalProperties,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displayModeChanged]
  );

  // Reset the displayModeChanged state when the currentDisplayMode changes, to regen the callback functions
  useEffect(() => {
    setDisplayModeChanged(!displayModeChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDisplayMode]);

  return { renderSegmentSnippet, segmentTrack, segmentPage };
};

export default useTracking;
