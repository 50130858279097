module.exports = {
  "environment": "canary",
  "cookieHost": ".hausgold.de",
  "trustedDomain": ".hausgold.de",
  "url": "https://maklerportal-next.canary.hausgold.de",
  "googleMaps": {
    "apiKey": "AIzaSyAFpizXXWg7JHe83p3gBr4EedYrVmkPVwA"
  },
  "segment": {
    "apiKey": "TKaoxEYIPYav4DbdbsCzZvnDYZNpWQAl"
  },
  "deployment": {
    "commit": "59127e7bb936a3a7fd2b608a52a465bc7eb8c5bb",
    "branch": "master",
    "deployedAt": "2025-01-27T00:26:26Z",
    "s3Bucket": "maklerportal-next-frontend-canary-master"
  },
  "appsignal": {
    "appName": "Maklerportal Next Frontend",
    "key": "48c67c52-fa43-4f18-a630-4869e30955c9",
    "pushKey": "393e9a6e-3c5a-4eb8-ac98-e3fe82b499cf",
    "revision": "59127e7bb936a3a7fd2b608a52a465bc7eb8c5bb",
    "branch": "master",
    "active": true
  },
  "firebase": {
    "apiKey": "AIzaSyD0OoaeUWfvC9Q0qicB2RQ0GQjEcaF03cs",
    "authDomain": "hausgold-canary.firebaseapp.com",
    "databaseURL": "https://hausgold-canary.firebaseio.com",
    "projectId": "hausgold-canary",
    "storageBucket": "hausgold-canary.appspot.com",
    "messagingSenderId": "431836656218",
    "appId": "1:431836656218:web:5a24722eb66ac804481e63",
    "measurementId": "G-6P6GZB8SHT"
  },
  "features": {
  },
  "zap": {
    "support": "https://hooks.zapier.com/hooks/catch/2548295/3qh50ga/"
  },
  "contentful": {
    "host": "preview.contentful.com",
    "space": "5qea3umoe8gl",
    "accessToken": "6pcod9kgtIVrNuTeQkOnlQ8oWO0Mvvn9sykmVmL-hPU"
  }
};
